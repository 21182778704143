import React, { useRef, useEffect } from "react";
import { graphql } from 'gatsby';

import Layout from "../components/layout";
import SEO from "../components/seo";

const configureActionSelect = (dom) => {
    window.$(dom).find('input[name="action"]').change(function() {
        window.$(this).closest('form').find('.af-step-next button').each(function() {
            window.$(this).click();
        });
    });
}

const ContactPage = ({ pageContext, data }) => {
    const messageRef = useRef();
    const arenguFormRef = useRef();

    useEffect(() => {
        if (typeof window === 'undefined' || !messageRef || !messageRef.current) {
            return;
        }

        let callback = null;
        window.drift.on('ready', function(api) {
            callback = function(e) {
                e.preventDefault();
                api.openChat();
            };

            if (messageRef && messageRef.current) {
                messageRef.current.querySelector('a').addEventListener('click', callback);
            }
        });

        return () => {
            if (callback && messageRef && messageRef.current) {
                messageRef.current.querySelector('a').removeEventListener('click', callback);
            }
        }
    }, [ messageRef ]);

    useEffect(() => {
        if (typeof window === 'undefined' || !arenguFormRef || !arenguFormRef.current) {
            return;
        }

        const setupArenguForm = () => {
            window.ArenguForms.embed(data.contactFormId, arenguFormRef.current).then(function() {
                configureActionSelect(arenguFormRef.current);
            });
        }

        if (typeof window.ArenguForms === 'undefined') {
            window.document.addEventListener('af-init', setupArenguForm);
        } else {
            setupArenguForm();
        }

        return () => {
            window.document.removeEventListener('af-init', setupArenguForm);
        }
    }, [ arenguFormRef ]);
    
    return (
        <Layout {...pageContext} showContact={false}>
            <SEO 
                lang={pageContext.lang}
                path={pageContext.pageUrl}
                title={data.seoTitleText}
                description={data.seoDescriptionText}
                />
            <div id="header">
				<div className="section-content">
					<div className="big-text">
                        {data.contactTitle ? (<h2 className="t-left f-large normal">{data.contactTitle}</h2>) : null}
                        <div className="separator-small"></div>
                        {data.contactSubtitle ? (<h3 className="t-left f-medium normal" dangerouslySetInnerHTML={{ __html: data.contactSubtitle }} />) : null}
                        <br />
                        {data.contactTextEmail ? (<h3 className="t-left f-normal normal link-underline" dangerouslySetInnerHTML={{ __html: `<span role="img" aria-label="Email">✉️</span> ${data.contactTextEmail}` }} />) : null}
                        {data.contactTextPhone ? (<h3 className="t-left f-normal normal link-underline" dangerouslySetInnerHTML={{ __html: `<span role="img" aria-label="Email">☎️</span> ${data.contactTextPhone}` }} />) : null}
                        {data.contactTextMessage ? (<h3 ref={messageRef} className="hidden visible-sm-block visible-md-block visible-lg-block t-left f-normal normal link-underline" dangerouslySetInnerHTML={{ __html: `<span role="img" aria-label="Email">💬</span> ${data.contactTextMessage}` }} />) : null}
                        {data.contactTextWhatsapp ? (<h3 className="visible-xs-block t-left f-normal normal link-underline" dangerouslySetInnerHTML={{ __html: `<span role="img" aria-label="Email">💬</span> ${data.contactTextWhatsapp}` }} />) : null}
                        {data.contactTextForm ? (<h3 className="t-left f-normal normal link-underline" dangerouslySetInnerHTML={{ __html: `<span role="img" aria-label="Email">⬇️</span> ${data.contactTextForm}` }} />) : null}
                        <div className="contact-form" ref={arenguFormRef}></div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ContactPage

export const pageQuery = graphql`
    query ContactPage($lang: String!) {
        seoTitleText: getTranslation(lang: $lang, key: "contact_seo_title")
        seoDescriptionText: getTranslation(lang: $lang, key: "contact_seo_description")

        contactTitle: getTranslation(lang: $lang, key: "contact_title")
        contactSubtitle: getTranslation(lang: $lang, key: "contact_subtitle", html: true, removeWrapper: true)
        contactTextEmail: getTranslation(lang: $lang, key: "contact_text_email")
        contactTextPhone: getTranslation(lang: $lang, key: "contact_text_phone")
        contactTextWhatsapp: getTranslation(lang: $lang, key: "contact_text_whatsapp")
        contactTextMessage: getTranslation(lang: $lang, key: "contact_text_message")
        contactTextForm: getTranslation(lang: $lang, key: "contact_text_form")
        contactFormId: getTranslation(lang: $lang, key: "contact_form_id")
    }
`